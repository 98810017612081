import { messages } from 'constants/webphoneMessages';
import { states } from '../constants/webphoneStates';

class ZenviaScriptService {
  /**
   * Start event capture for zenvia script
   */
  startOnMessage(
    handleStopCall: () => void,
    handleTalkingCall: () => void,
    handleIdCall: (idCall: number) => void,
    handleScriptConnected: () => void,
    handleScriptError: () => void,
  ): void {
    window.onmessage = (event: MessageEvent<{ message: string; status: string; chamada_id: string }>): void => {
      if (event.data.message == messages.STATUS) {
        if (event.data.status == states.CLOSED) {
          handleStopCall();
        }

        if (event.data.status == states.TALKING) {
          handleTalkingCall();
        }

        if (event.data.status == states.CONNECTED) {
          handleScriptConnected();
        }
      }

      if (event.data.message == messages.CALL_ID) {
        handleIdCall(parseInt(event.data.chamada_id));
      }

      if (event.data.message == messages.STATUS_ERROR) {
        handleScriptError();
      }
    };
  }

  /**
   * Post'chamaNumero' message to zenvia script
   * @param numberToCall the number to call
   */
  startCall(numberToCall: number): void {
    const { contentWindow }: HTMLIFrameElement = document.getElementById('webphone') as HTMLIFrameElement;

    if (contentWindow) {
      contentWindow.postMessage(
        {
          message: 'chamaNumero',
          numero: numberToCall,
        },
        '*',
      );
    }
  }

  /**
   * Post'hangup' message to zenvia script
   */
  stopCall(): void {
    const { contentWindow } = document.getElementById('webphone') as HTMLIFrameElement;

    if (contentWindow) {
      contentWindow.postMessage(
        {
          message: 'hangup',
        },
        '*',
      );
    }
  }

  /**
   * Post 'mute' message to zenvia script
   */
  mute(): void {
    const { contentWindow } = document.getElementById('webphone') as HTMLIFrameElement;

    if (contentWindow) {
      contentWindow.postMessage(
        {
          message: 'mute',
        },
        '*',
      );
    }
  }

  /**
   * Post 'dtmf' message to zenvia script
   * @param value the ura value
   */
  ura(value: number): void {
    const { contentWindow } = document.getElementById('webphone') as HTMLIFrameElement;

    if (contentWindow) {
      contentWindow.postMessage(
        {
          message: 'enviaDTMF',
          dtmf: value,
        },
        '*',
      );
    }
  }
}

export default new ZenviaScriptService();
