export const listNumbersKeypad = [
  {
    contentNumber: '1',
    contentText: '',
  },
  {
    contentNumber: '2',
    contentText: 'ABC',
  },
  {
    contentNumber: '3',
    contentText: 'DEF',
  },
  {
    contentNumber: '4',
    contentText: 'GHI',
  },
  {
    contentNumber: '5',
    contentText: 'JKL',
  },
  {
    contentNumber: '6',
    contentText: 'MNO',
  },
  {
    contentNumber: '7',
    contentText: 'PQRS',
  },
  {
    contentNumber: '8',
    contentText: 'TUV',
  },
  {
    contentNumber: '9',
    contentText: 'WXYZ',
  },
  {
    contentNumber: '*',
    contentText: '',
  },
  {
    contentNumber: '0',
    contentText: '',
  },
  {
    contentNumber: '#',
    contentText: '',
  },
];
