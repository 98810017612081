import { phoneNumberRegex } from '../constants/regex';
import { IMaskPhoneNumber } from '../interface';

export const applyMaskPhoneNumber = (value: string): IMaskPhoneNumber => {
  let phoneNumber = value?.replace(/\D/g, '');

  if (phoneNumber.length >= 12) {
    phoneNumber = phoneNumber.replace('55', '');
  }

  if (phoneNumber.length >= 10) {
    phoneNumber = phoneNumber.replace(/^0{1}/, '');
  }

  const phoneMaskedNumber = phoneNumber.replace(/^(\d{2})(\d)/, '($1) $2').replace(/(\d)(\d{4})$/, '$1-$2');

  return { phoneNumber, phoneMaskedNumber };
};

export const mathPhoneNumber = (phoneNumber: string): string => {
  const value = phoneNumber.match(phoneNumberRegex)?.at(0) || '';
  return value.trim().replace(/\D/g, '');
};

export const validatePhoneNumber = (value: string): boolean => {
  if (value.trim().length > 30) {
    return false;
  }

  if (!phoneNumberRegex.test(value)) {
    return false;
  }

  const phoneNumber = mathPhoneNumber(value);

  if (phoneNumber.length <= 12) {
    return true;
  }

  if (phoneNumber.length > 12 && phoneNumber.startsWith('55')) {
    return true;
  }

  return false;
};
