import React from 'react';

import stylesButton from '../../styles/components/button/button.module.css';

import { FaPhoneAlt } from 'react-icons/fa';

export interface ButtonTurnOnProps {
  numberCall: string;
  handleStartCall: () => void;
}

/**
 * The component ButtonTurnOn
 * @param numberCall the phone number
 * @param handleStartCall the method to start call
 * @returns the jsx element
 */
export function ButtonTurnOn({ numberCall, handleStartCall }: ButtonTurnOnProps): JSX.Element {
  /**
   * Action to start calling
   */
  function onClick(): void {
    if (numberCall.length >= 14) {
      handleStartCall();
    }
  }

  /**
   * Capture the keyboard events
   * @param event the data event
   */
  function onKeyDown(event: { key: string }): void {
    if (event.key === 'Enter') onClick();
  }

  return (
    <div role="button" className={stylesButton.ButtonTurnOn} onClick={onClick} onKeyDown={onKeyDown} tabIndex={0}>
      <FaPhoneAlt />
    </div>
  );
}
