import React from 'react';
import stylesLoader from '../../styles/components/loader/loader.module.css';

/**
 * The component loading
 * @returns the jsx element
 */
export function Loading(): JSX.Element {
  return <div className={stylesLoader.Loading}></div>;
}

/**
 * The component Loader
 * @returns the jsx element
 */
export default function Loader(): JSX.Element {
  return (
    <div className={stylesLoader.Blur}>
      <Loading />
    </div>
  );
}
