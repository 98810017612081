import React from 'react';

import './App.css';

import { AppRoutes } from './Router';

/**
 * The component App
 * @returns the jsx element
 */
function App(): JSX.Element {
  return (
    <div className="Dialer-Content">
      <AppRoutes />
    </div>
  );
}

export default App;
