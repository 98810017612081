import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { HiPhoneOutgoing } from 'react-icons/hi';
import styles from '../../styles/components/timer/timer.module.css';

interface TimerProps {
  seconds: number;
  webphoneStates: string;
}

/**
 * The component Timer
 * @param webphoneStates the web phone status
 * @param seconds the time number of the call
 * @returns the jsx element
 */
export default function Timer({ webphoneStates, seconds }: TimerProps): JSX.Element {
  return (
    <div className={styles.TimerContent}>
      <div>
        <div className={styles.DialerTimeCall}>
          <div>
            {Math.floor(seconds / 60) < 10 ? `0${Math.floor(seconds / 60)}` : Math.floor(seconds / 60)} :{' '}
            {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
          </div>
        </div>
        <div className={styles.DialerTimeText}>
          {
            {
              chamando: (
                <>
                  <HiPhoneOutgoing style={{ margin: '0 8px 0' }} />
                  <div style={{ width: '106px', display: 'flex' }}>
                    Chamando
                    <div className={styles.typingAnimation}>...</div>
                  </div>
                </>
              ),
              encerrada: (
                <>
                  <FaPhoneAlt style={{ margin: '0 8px 0' }} />
                  <div style={{ width: '12rem', display: 'flex' }}>
                    Encerrando chamada
                    <div className={styles.typingAnimation}>...</div>
                  </div>
                </>
              ),
            }[webphoneStates]
          }
        </div>
      </div>
    </div>
  );
}
