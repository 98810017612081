import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import React from 'react';
import Error from './pages/error';
import ToCall from './pages/toCall';

/**
 * The Component AppRoutes
 * @returns the jsx element
 */
export function AppRoutes(): JSX.Element {
  return (
    <Router>
      <Routes>
        {/* <Route path="/open-webphone" element={<OpenWebphone />} /> */}
        <Route path="/v2/call/:webphoneToken" element={<ToCall />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </Router>
  );
}
