export interface WebphoneStates {
  CONNECTED: string;
  CALLING: string;
  TALKING: string;
  CLOSED: string;
  ID_CALL: string;
}

export const states: WebphoneStates = {
  CONNECTED: 'conectado',
  CALLING: 'chamando',
  TALKING: 'conversando',
  CLOSED: 'encerrada',
  ID_CALL: 'chamada_id',
};
