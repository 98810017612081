import React, { useContext } from 'react';

import Keypad from 'components/keypad/keypad';

import { NumberCallPopup } from 'components/numberCallPopup/numberCallPopup';
import { ButtonTurnOn } from 'components/buttonTurnOn/ButtonTurnOn';
import { applyMaskPhoneNumber } from 'helpers/phoneNumber';
import { NumberCallView } from '../numberCallView/numberCallView';
import { ToCallContext } from '../../pages/toCall';

/**
 * The component Call
 * @returns the jsx element
 */
export default function Call(): JSX.Element {
  const { numberCall, webphoneStates, handleStartCall, handleUpdateNumberCall } = useContext(ToCallContext);

  /**
   * Concatenate the digit to the phone number
   * @param digit the phone number digit
   */
  function handleTypedNumberByKeypad(digit: string): void {
    handleUpdateNumberCall(`${numberCall}${digit}`);
  }

  /**
   * Erases the last digit from the phone
   */
  function handleErasePhoneNumber(): void {
    handleUpdateNumberCall(numberCall.slice(0, -1));
  }

  /**
   * Paste the phone number
   * @param number the phone number
   */
  function handlePasteNumber(number: string): void {
    handleUpdateNumberCall(number);
  }

  return (
    <div>
      <NumberCallView numberCall={numberCall} handleErasedNumber={handleErasePhoneNumber} />
      <NumberCallPopup
        phoneNumber={applyMaskPhoneNumber(numberCall).phoneNumber}
        handlePasteNumber={handlePasteNumber}
      />
      <Keypad
        handleClickerNumber={handleTypedNumberByKeypad}
        webphoneStates={webphoneStates}
        handleErasedNumber={handleErasePhoneNumber}
        handlePasteNumber={handlePasteNumber}
      />
      <ButtonTurnOn numberCall={numberCall} handleStartCall={handleStartCall} />
    </div>
  );
}
