import { useLocation } from 'react-router-dom';

import CantCallIcon from '../assets/svgs/cantCallIcon.svg';

import styles from '../styles/pages/error.module.css';

/**
 * The page Error
 * @returns the jsx element
 */
export default function Error(): JSX.Element {
  const location = useLocation();
  let message = <p>Não podemos realizar essa ligação no momento. Entre em contato com o administrador da conta.</p>;
  if (location.state) {
    const { status } = location.state as { status: number };
    if (status == 401) {
      message = (
        <p>
          Sua sessão expirou, entre no Ramper e clique em <strong>Fazer Ligação</strong> para acessar o Webphone
          novamente.
        </p>
      );
    }
  }

  return (
    <div className={styles.ContainerPage}>
      <div>
        <div className={styles.IconCantCall}>
          <img src={CantCallIcon} alt="Cant Call Icon" />
        </div>
        <div className={styles.TextCantCall}>{message}</div>
      </div>
    </div>
  );
}
