import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { IVoipDataResponse } from 'interface';

interface IVoipResponse {
  statusCode: number;
  message: string;
  data: IVoipDataResponse;
}

class VoipService {
  private http: AxiosInstance;

  /**
   * Constructor
   */
  constructor() {
    const { REACT_APP_BASE_URL_MS_VOIP } = process.env;
    this.http = axios.create({ baseURL: REACT_APP_BASE_URL_MS_VOIP });
  }

  /**
   * Get html script in ms voip
   * @param webphoneToken the jwt token for webphone
   * @returns a html script to upload zenvia script
   */
  async getZenviaScript(webphoneToken: string): Promise<IVoipDataResponse> {
    const headers = { 'webphone-token': webphoneToken } as AxiosRequestHeaders;
    const response = await this.http.get<IVoipResponse>(`/v2/extensions/script`, { headers });

    if (response.status === 200) {
      return response.data.data;
    }

    return { script: '' } as IVoipDataResponse;
  }

  /**
   * Create a calls documents from start call in zenvia script
   * @param webphoneToken the jwt token for webphone
   * @param idCallZenvia the id call in zenvia
   * @param tag the value from user identifier
   */
  createCalls(webphoneToken: string, idCallZenvia: number, tag?: string | null): void {
    const headers = { 'webphone-token': webphoneToken } as AxiosRequestHeaders;
    void this.http.post<IVoipResponse>(`/v2/calls/start-call`, { idCallZenvia, tag }, { headers });
  }

  /**
   * Validate JWT
   * @param webphoneToken the jwt token for webphone
   * @returns a html script to upload zenvia script
   */
  async validateToken(webphoneToken: string): Promise<void> {
    const headers = { 'webphone-token': webphoneToken } as AxiosRequestHeaders;
    await this.http.post<IVoipResponse>(`/v2/calls/validate`, null, { headers });
  }
}

export default new VoipService();
