import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { applyMaskPhoneNumber, validatePhoneNumber } from '../helpers/phoneNumber';
import { states } from '../constants/webphoneStates';
import { AxiosError } from 'axios';

import Loader from '../components/loader/loader';
import styles from '../styles/pages/toCall.module.css';
import Calling from '../components/calling/calling';
import voipService from '../services/voip.service';
import zenviaScriptService from '../services/zenvia-script.service';
import Call from '../components/call/call';

interface IToCallContext {
  numberCall: string;
  webphoneStates: string;

  handleUpdateNumberCall: (number: string) => void;
  handleStopCall: () => void;
  handleStartCall: () => void;
}

export const ToCallContext = React.createContext<IToCallContext>({} as IToCallContext);

/**
 * The page ToCall
 * @returns the jsx element
 */
export default function ToCall(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [numberCall, setNumberCall] = useState('');
  const [webphoneStates, setWebphoneStates] = useState<string>(states.CONNECTED);

  const { webphoneToken } = useParams();

  const location = useLocation();
  const closeOnEnd = new URLSearchParams(location.search).get('close-on-end');
  const phoneNumber = new URLSearchParams(location.search).get('phone-number');
  const tag = new URLSearchParams(location.search).get('tag');

  const navigate = useNavigate();

  /**
   * Make a call on zenvia
   */
  function handleStartCall(): void {
    const { phoneNumber } = applyMaskPhoneNumber(numberCall);
    const numberToCall = parseInt(phoneNumber, 10);

    voipService
      .validateToken(webphoneToken || '')
      .then(() => {
        zenviaScriptService.startCall(numberToCall);
        setWebphoneStates(states.CALLING);
      })
      .catch((error) => {
        const { response } = error as AxiosError;
        const options = { state: { status: response?.status } };
        navigate('/error', options);
      });
  }

  /**
   * Update status to TALKING
   */
  function handleTalkingCall(): void {
    setWebphoneStates(states.TALKING);
  }

  /**
   * create a new document from getting the call id
   */
  function handleIdCall(idCallZenvia: number): void {
    if (webphoneToken) {
      voipService.createCalls(webphoneToken, idCallZenvia, tag);
    }
  }

  /**
   * Connected to handle Zenvia script
   */
  function handleScriptConnected(): void {
    setIsLoading(false);
  }

  /**
   * Error to handle Zenvia script
   */
  function handleScriptError(): void {
    setIsLoading(false);
    navigate('/error');
  }

  /**
   * Update the phone number with mask
   * @param number the phone number
   */
  function handleUpdateNumberCall(number: string): void {
    const newNumber = applyMaskPhoneNumber(number);

    if (newNumber.phoneNumber.length < 12) {
      setNumberCall(newNumber.phoneMaskedNumber);
    }
  }

  /**
   * Stop of the call in Zenvia.
   * Update status to CLOSED.
   * after timeout 2000, update status to CONNECT
   */
  function handleStopCall(): void {
    zenviaScriptService.stopCall();
    setWebphoneStates(states.CLOSED);

    setTimeout(() => {
      if (closeOnEnd === 'true') {
        window.opener = null;
        window.open('', '_self');
        window.close();
      } else {
        setWebphoneStates(states.CONNECTED);
        setNumberCall('');
      }
    }, 2000);
  }

  useEffect(() => {
    if (phoneNumber && validatePhoneNumber(phoneNumber)) {
      handleUpdateNumberCall(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    setIsLoading(true);

    const getZenviaScript = async (): Promise<void> => {
      await voipService
        .getZenviaScript(webphoneToken || '')
        .then((data) => {
          const existingScript = document.getElementById('webphone-zenvia-script');

          if (!existingScript) {
            const script = document.createRange().createContextualFragment(data.script);
            document.body.appendChild(script);
          }
        })
        .catch((error) => {
          const { response } = error as AxiosError;
          const options = {
            state: {
              status: response?.status,
            },
          };
          navigate('/error', options);
        });
    };

    getZenviaScript()
      .then(() =>
        zenviaScriptService.startOnMessage(
          handleStopCall,
          handleTalkingCall,
          handleIdCall,
          handleScriptConnected,
          handleScriptError,
        ),
      )
      .catch(handleScriptError);
  }, [webphoneToken]);

  const toCallContext: IToCallContext = {
    numberCall,
    webphoneStates,

    handleUpdateNumberCall,
    handleStopCall,
    handleStartCall,
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className={styles.ToCallContainer}>
        <ToCallContext.Provider value={{ ...toCallContext }}>
          {webphoneStates == states.CONNECTED ? <Call /> : <Calling />}
        </ToCallContext.Provider>
      </div>
    </>
  );
}
