import React, { useEffect } from 'react';

import { validatePhoneNumber } from '../../helpers/phoneNumber';
import { states } from '../../constants/webphoneStates';
import { ButtonNumberKeypad } from '../button/button';
import { IKeypadProps } from '../../interface';
import { listNumbersKeypad } from '../../constants/listKeypad';

import styles from '../../styles/components/keypad/keypad.module.css';

/**
 * The component Keypad
 * @param webphoneStates the webphone status
 * @param handleClickerNumber the method to set digit
 * @param handleErasedNumber the method to erased number
 * @param handlePasteNumber the method to paste phone number
 */
export default function Keypad({
  webphoneStates,
  handleClickerNumber,
  handleErasedNumber,
  handlePasteNumber,
}: IKeypadProps): JSX.Element {
  const numbersKeypad = listNumbersKeypad;

  /**
   * Capture the keyboard events
   * @param event the data event
   */
  function onKeyDown(event: KeyboardEvent): void {
    const { key } = event;

    if (numbersKeypad.find((keypad) => keypad.contentNumber === event.key)) {
      if (['#', '*'].includes(key) && webphoneStates === states.CONNECTED) {
        return;
      }

      handleClickerNumber(key);
    }

    if (key === 'Backspace' && webphoneStates === states.CONNECTED && handleErasedNumber) {
      handleErasedNumber();
    }
  }

  /**
   * Capture the clipboard events
   * @param event the data event
   */
  function onPaste(event: ClipboardEvent): void {
    const value = (event.clipboardData as DataTransfer).getData('text/plain');

    if (webphoneStates === states.CONNECTED && handlePasteNumber && validatePhoneNumber(value)) {
      handlePasteNumber(value);
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('paste', onPaste);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('paste', onPaste);
    };
  });

  /**
   * render the buttons to keypad
   * @param contentNumber the keypad number
   * @param contentText the keypad text
   * @returns the jsx element
   */
  function renderButtonKeypad(contentNumber: string, contentText: string): JSX.Element {
    return (
      <ButtonNumberKeypad
        contentNumber={contentNumber}
        contentText={contentText}
        handleTypedNumber={handleClickerNumber}
        key={`${contentNumber}`}
      />
    );
  }

  return (
    <div className={styles.KeypadContent}>
      {numbersKeypad.map((number) => renderButtonKeypad(number.contentNumber, number.contentText))}
    </div>
  );
}
