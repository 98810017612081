import React, { useState } from 'react';

import { AudioOutlined, AudioMutedOutlined } from '@ant-design/icons';
import { IoMdKeypad } from 'react-icons/io';
import { FaPhoneAlt } from 'react-icons/fa';
import { IKeypadButtonProps } from '../../interface';

import styles from '../../styles/components/button/button.module.css';

interface ButtonMuteCallProps {
  updateHandleMute: () => void;
}

/**
 * The component ButtonMuteCall
 * @param updateHandleMute the method to mute call
 * @returns the jsx element
 */
export function ButtonMuteCall({ updateHandleMute }: ButtonMuteCallProps): JSX.Element {
  const [mute, setMute] = useState(false);

  /**
   * Mute or unmute the keyboard
   */
  function handleMuteButton(): void {
    setMute(!mute);
    updateHandleMute();
  }

  /**
   * Capture the keyboard events
   * @param event the data event
   */
  function onKeyDown(event: { key: string }): void {
    if (event.key === 'Enter') {
      setMute(!mute);
      updateHandleMute();
    }
  }

  return (
    <div>
      {mute ? (
        <div
          role="button"
          className={`${styles.ButtonMute} ${styles.ButtonPressed}`}
          onClick={handleMuteButton}
          onKeyDown={onKeyDown}
          tabIndex={0}
        >
          <AudioMutedOutlined />
          Mudo
        </div>
      ) : (
        <div role="button" className={styles.ButtonMute} onClick={handleMuteButton} onKeyDown={onKeyDown} tabIndex={0}>
          <AudioOutlined />
          Mudo
        </div>
      )}
    </div>
  );
}
interface ButtonToKeypadProps {
  updateHandleShowKeypad: () => void;
}

/**
 * The component ButtonToKeypad
 * @param updateHandleShowKeypad the method to show keypad
 * @returns the jsx element
 */
export function ButtonToKeypad({ updateHandleShowKeypad }: ButtonToKeypadProps): JSX.Element {
  const [keypad, setKeypad] = useState(false);

  /**
   * Show or hide the keyboard
   */
  function handleKeyPadButton(): void {
    setKeypad(!keypad);
    updateHandleShowKeypad();
  }

  /**
   * Capture the keyboard events
   * @param event the data event
   */
  function onKeyDown(event: { key: string }): void {
    if (event.key === 'Enter') {
      setKeypad(!keypad);
      updateHandleShowKeypad();
    }
  }

  return (
    <div
      role="button"
      className={`${styles.ButtonToKeypad} ${keypad ? styles.ButtonPressed : ''}`}
      onClick={handleKeyPadButton}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      <span>
        <IoMdKeypad />
      </span>
      Teclado
    </div>
  );
}

interface ButtonsTurnOffCallProps {
  updateHandleStopCall: () => void;
}

/**
 * The component ButtonTurnOffCall
 * @param updateHandleStopCall the method to stop call
 * @returns the jsx element
 */
export function ButtonTurnOffCall({ updateHandleStopCall }: ButtonsTurnOffCallProps): JSX.Element {
  /**
   * Capture the keyboard events
   * @param event the data event
   */
  function onKeyDown(event: { key: string }): void {
    if (event.key === 'Enter') updateHandleStopCall();
  }

  return (
    <div
      role="button"
      className={styles.ButtonTurnOff}
      onClick={updateHandleStopCall}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      <FaPhoneAlt />
    </div>
  );
}

/**
 * the component ButtonNumberKeypad
 * @param contentNumber the keypad number
 * @param contentText The keypad text
 * @param handleTypedNumber the method to set digit
 * @returns the jsx element
 */
export function ButtonNumberKeypad({ contentNumber, contentText, handleTypedNumber }: IKeypadButtonProps): JSX.Element {
  /**
   * Capture the keyboard events
   * @param event the data event
   * @param digit the phone number digit
   */
  function onKeyDown(event: { key: string }, digit: string): void {
    if (event.key === 'Enter') handleTypedNumber(digit);
  }

  return (
    <div
      role="button"
      id={`button_number_keypad_${contentNumber}`}
      className={styles.ButtonKeypad}
      onClick={(): void => handleTypedNumber(contentNumber)}
      onKeyDown={(event): void => onKeyDown(event, contentNumber)}
      tabIndex={0}
    >
      <div className={styles.NumberButtonKeypad}>{contentNumber}</div>
      <div className={styles.LettersButtonKeypad}>{contentText}</div>
    </div>
  );
}
