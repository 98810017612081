import React from 'react';

import { ButtonMuteCall, ButtonToKeypad, ButtonTurnOffCall } from '../button/button';

import styles from '../../styles/components/buttonsCalling/buttonsCalling.module.css';

interface ButtonsCallingProps {
  updateHandleStopCall: () => void;
  updateHandleMute: () => void;
  updateHandleShowKeypad: () => void;
}

/**
 * The component ButtonsCalling
 * @param updateHandleStopCall the method to stop call
 * @param updateHandleMute the method to mute call
 * @param updateHandleShowKeypad the method to show keypad
 * @returns the jsx element
 */
export default function ButtonsCalling({
  updateHandleStopCall,
  updateHandleMute,
  updateHandleShowKeypad,
}: ButtonsCallingProps): JSX.Element {
  return (
    <div>
      <div className={styles.MuteAndKeypadButtons}>
        <ButtonMuteCall updateHandleMute={updateHandleMute} />
        <ButtonToKeypad updateHandleShowKeypad={updateHandleShowKeypad} />
      </div>

      <div className={styles.TurnOffButton}>
        <ButtonTurnOffCall updateHandleStopCall={updateHandleStopCall} />
      </div>
    </div>
  );
}
