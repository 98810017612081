import React, { useEffect, useState } from 'react';

import { INumberCallPopupProps } from '../../interface';
import { applyMaskPhoneNumber, mathPhoneNumber, validatePhoneNumber } from '../../helpers/phoneNumber';

import styles from '../../styles/components/numberCallPopup/numberCallPopup.module.css';

/**
 * The component NumberCallPopup
 * @param phoneNumber the call number
 * @returns the jsx element
 */
export function NumberCallPopup({ phoneNumber, handlePasteNumber }: INumberCallPopupProps): JSX.Element {
  const [pasteNumber, setPasteNumber] = useState('');
  const [phoneMaskedNumber, setPhoneMaskedNumber] = useState('');
  const [tabHasFocus, setTabHasFocus] = useState(true);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (tabHasFocus) {
        const clipboardText = await navigator.clipboard.readText();

        if (validatePhoneNumber(clipboardText)) {
          const mask = applyMaskPhoneNumber(mathPhoneNumber(clipboardText));

          setPhoneMaskedNumber(mask.phoneMaskedNumber);
          setPasteNumber(mask.phoneNumber);
        } else {
          setPhoneMaskedNumber('');
          setPasteNumber('');
        }
      }
    }, 500);

    const handleFocus = (): void => setTabHasFocus(true);
    const handleBlur = (): void => setTabHasFocus(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);

      clearInterval(interval);
    };
  });

  /**
   * Update phone number
   */
  function onClick(): void {
    handlePasteNumber(pasteNumber);
  }

  /**
   * Capture the keyboard events
   * @param event the data event
   */
  function onKeyDown(event: { key: any }): void {
    if (event.key === 'Enter') onClick();
  }

  /**
   * Get template popup to indicate the call number
   */
  function handlePopupTemplate(): JSX.Element {
    return (
      <div
        role="button"
        className={styles.NumberCallPopupContainer}
        onClick={onClick}
        onKeyDown={onKeyDown}
        tabIndex={0}
      >
        <div className={styles.NumberCallPopupArrow}></div>
        Colar {phoneMaskedNumber}
      </div>
    );
  }

  return <div>{phoneNumber !== pasteNumber && phoneMaskedNumber !== '' ? handlePopupTemplate() : null}</div>;
}
