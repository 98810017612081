import React, { useContext, useEffect, useState } from 'react';

import { states } from 'constants/webphoneStates';
import { NumberCallView } from '../numberCallView/numberCallView';
import { ToCallContext } from '../../pages/toCall';

import zenviaScriptService from 'services/zenvia-script.service';
import ButtonsCalling from '../buttonsCalling/buttonsCalling';
import Timer from '../timer/timer';
import styles from '../../styles/components/calling/calling.module.css';
import Keypad from 'components/keypad/keypad';

/**
 * The component Calling
 */
export default function Calling(): JSX.Element {
  const [numberUra, setNumberUra] = useState('');
  const [showKeypad, setShowKeypad] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const { numberCall, webphoneStates, handleStopCall } = useContext(ToCallContext);

  useEffect(() => {
    if (webphoneStates === states.TALKING) {
      const myInterval = setInterval(() => {
        setSeconds(seconds + 1);
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }

    if (webphoneStates === states.CLOSED) {
      setShowKeypad(false);
    }
  }, [seconds, webphoneStates]);

  /**
   * Update the ura number
   * @param value the ura number
   */
  function handleUpdateNumberUra(value: string): void {
    const number = value.replace('[0-9][#*]/g', '');
    setNumberUra(number);
    zenviaScriptService.ura(+number.slice(-1));
  }

  /**
   * Concatenate the digit with the ura numbers
   * @param digit the number entered
   * @returns the jsx element
   */
  function handleClickerNumber(digit: string): void {
    handleUpdateNumberUra(`${numberUra}${digit}`);
  }

  /**
   * Show keypad
   */
  function updateHandleShowKeypad(): void {
    setShowKeypad(!showKeypad);
  }

  return (
    <div className={styles.CallingContainer}>
      <NumberCallView numberCall={showKeypad ? numberUra : numberCall} />

      {showKeypad ? (
        <Keypad handleClickerNumber={handleClickerNumber} webphoneStates={webphoneStates} />
      ) : (
        <Timer webphoneStates={webphoneStates} seconds={seconds} />
      )}

      <ButtonsCalling
        updateHandleStopCall={handleStopCall}
        updateHandleMute={zenviaScriptService.mute}
        updateHandleShowKeypad={updateHandleShowKeypad}
      />
    </div>
  );
}
