export interface WebphoneMessages {
  STATUS: string;
  STATUS_ERROR: string;
  CALL_ID: string;
}

export const messages: WebphoneMessages = {
  STATUS: 'status',
  STATUS_ERROR: 'status_erro',
  CALL_ID: 'chamada_id',
};
