import React from 'react';

import { IoMdBackspace } from 'react-icons/io';
import { INumberCallViewProps } from '../../interface';

import styles from '../../styles/components/numberCallView/numberCallView.module.css';

/**
 * The component NumberCallView
 * @param numberCall the call number
 * @returns the jsx element
 */
export function NumberCallView({ numberCall, handleErasedNumber }: INumberCallViewProps): JSX.Element {
  return (
    <div className={styles.NumberCallViewContainer}>
      <label className={styles.NumberCallViewText}>{numberCall}</label>
      {numberCall && handleErasedNumber ? (
        <IoMdBackspace size={30} className={styles.NumberCallViewBackSpace} onClick={handleErasedNumber} />
      ) : (
        ''
      )}
    </div>
  );
}
